<template>
<el-dialog
    title="新增/编辑"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
    @close="close"
    :visible="value"
>
  <div class="body">
    <input-with-error
        class="m-b-20 m-t-25"
        showLabel
        :label="form.content.label"
        :error-info="errorInfo.content"
        :row-info.sync="form.content"
        @validate="validateRow"
        :key="form.content.updateKey"
    />
    <input-with-error
        class="m-b-20 m-t-25"
        showLabel
        :label="form.sort.label"
        :error-info="errorInfo.sort"
        :row-info.sync="form.sort"
        @validate="validateRow"
        :key="form.sort.updateKey"
    />
    <div class="row row-inner">
      <span class="label d-inline-block">是否开启</span>
      <el-switch v-model="form.status.value"></el-switch>
      <span>{{form.status.value ? '是' : '否' }}</span>
    </div>
    <template v-if="isSuper">
      <input-with-error
          class="m-b-20 m-t-25"
          showLabel
          :label="form.jumpUrl.label"
          :error-info="errorInfo.jumpUrl"
          :row-info.sync="form.jumpUrl"
          @validate="validateRow"
          :key="form.jumpUrl.updateKey"
      />
      <div class="row row-inner">
        <span class="label d-inline-block">跳转方式</span>
        <el-radio-group v-model="form.jumpType.value">
          <el-radio :label="1">内跳</el-radio>
          <el-radio :label="2">外跳</el-radio>
        </el-radio-group>
      </div>
    </template>

  </div>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="confirm">提交</el-button>
    <el-button @click="close">取消</el-button>
  </div>
</el-dialog>
</template>

<script>
import InputWithError from "@components/Form/InputWithError";
import {isRequire} from "@/util/validator";
import {isEmpty, omit} from "@/util/lodashUtil";
import { addSpeech, editSpeech } from "@/api/content";
import {statusCode} from "@/util/statusCode";

export default {
  name: "DialogEditSpeech",
  components: {
    InputWithError
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    isSuper: {
      type: Boolean,
      default: false
    },
    value: {
      type:Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        content: {
          label: '内容',
          value: null,
          key: 'content',
          validators: [isRequire('内容')],
          validateLabel: ['isRequire'],
          updateKey: 'content-false'
        },
        status: {
          value: true,
          key: 'status',
          validators: [],
          validateLabel: [],
          updateKey: 'status-false'
        },
        sort: {
          label: '排序',
          value: null,
          key: 'sort',
          validators: [isRequire('排序')],
          validateLabel: ['isRequire'],
          updateKey: 'sort-false'
        },
        jumpUrl: {
          label: '链接',
          value: null,
          key: 'jumpUrl',
          validators: [isRequire('链接')],
          validateLabel: ['isRequire'],
          updateKey: 'jumpUrl-false'
        },
        jumpType: {
          label: '跳转类型',
          value: 1,
          key: 'jumpType',
          validators: [],
          validateLabel: [],
          updateKey: 'jumpType-false'
        }
      },
      errorInfo: {
        status: {},
        content: {},
        sort: {},
        jumpUrl: {},
        jumpType: {}
      }
    }
  },
  watch: {
    value: {
      handler() {
        if(this.value) {
          this.initForm()
        }
      },
      immediate: true,
    }
  },
  methods: {
    initForm() {
        this.form.content.value = this.rowData.content
        this.form.sort.value = this.rowData.sort
        this.form.jumpUrl.value = this.rowData.jump_url
        this.form.status.value = this.rowData.status === 1
        this.form.jumpType.value = this.rowData.jump_type || 1
    },
    close() {
      this.reset()
      this.$emit('update:value', false)
    },
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key].value = null
        this.errorInfo[key] = {}
      })
      this.form.jumpType.value = 1
    },
    validate () {
      const res = []
      Object.keys(this.form).forEach(key => {
        if (!this.isSuper && key === 'jumpUrl') {

        } else {
          res.push(this.validateRow(key))
        }
      })
      return res.every(x => x)
    },
    validateRow (key) {
      this.form[key].validators.forEach(validator => {
        const { message, type } = validator(this.form[key].value)
        if (message) {
          this.errorInfo[key][type] = message
        } else {
          this.errorInfo[key] = omit(this.errorInfo[key], type, message)
        }
      })
      this.changeKey(key)
      return isEmpty(this.errorInfo[key])
    },
    changeKey (key) {
      const flag = JSON.parse(this.form[key].updateKey.split('-')[1])
      this.form[key].updateKey = `${key}-${!flag}`
    },
    async confirm() {
      const isValidate = this.validate()
      if (isValidate) {
        const params = this.finalData()
        let msg = '操作成功',type = 'success'
        const request = this.isAdd ? addSpeech  : editSpeech
        try {
          const { data } = await request(params)
          if (data.code === statusCode.success) {
            this.addDialog = false
            this.$emit('refresh')
            this.close()
          } else {
            msg = data.msg
            type = 'warning'
          }
          this.$message({
            message: msg,
            type,
            duration: 2000
          });
        } catch (e) {
          console.log(e, '出错了')
        }
      }
    },
    finalData() {
      const params = {
        content: this.form.content.value,
        sort: this.form.sort.value * 1,
        type: this.isSuper ? 2 : 1,
        status: this.form.status.value ? 1 : 2
      }
      if (!this.isAdd) {
        params.id = this.rowData.id
      }
      if (this.isSuper) {
        params.jumpUrl = this.form.jumpUrl.value
        params.jumpType = this.form.jumpType.value
      }
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  //弹窗
  //width: 400px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

::v-deep {
  .row-inner {
    max-width: 570px;
    padding-left: 30px!important;
    .label {
      width: 80px;
      line-height: 30px;
      margin-right: 45px;

    }
    .input-section {
      background-color: transparent!important;
      border: 1px solid #E2E1E1;
      border-radius: 2px;
    }
    .el-input__icon {
      line-height: 30px;
    }
    .error{
      left: 157px;
    }
  }
}
</style>
