<template>
<div class="page-box">
  <el-button @click="addContent">新增</el-button>
  <div class="table-box p-t-15">
    <el-table
        :data="tableData"
        :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
        height="100%"
        style="width: 100%"
    >
      <el-table-column
          align="center"
          type="index"
          :min-width="50"
          label="序号"></el-table-column>
      <el-table-column
          align="center"
          prop="content"
          :min-width="120"
          show-overflow-tooltip
          label="内容"></el-table-column>
      <el-table-column
          align="center"
          prop="sort"
          :min-width="100"
          label="排序">
      </el-table-column>
      <template v-if="isSuper">
        <el-table-column
            align="center"
            prop="sort"
            :min-width="80"
            label="跳转类型">
          <template slot-scope="scope">
            <span class="">{{ scope.row.jump_type === 1 ? '内跳' : '外跳'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="jump_url"
            :min-width="120"
            label="跳转链接">
        </el-table-column>
      </template>
      <el-table-column
          align="center"
          prop="update_time"
          :min-width="120"
          show-overflow-tooltip
          label="编辑时间">
<!--        <template slot-scope="scope">-->
<!--          <span>{{ new DATA}}</span>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column
          align="center"
          prop="update_time"
          :min-width="80"
          label="状态">
        <template slot-scope="scope">
          <status-button
              :tips="['启用', '禁用']"
              target="发言"
              no-event
              :value="scope.row.status == 1"
          />
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="update_by"
          show-overflow-tooltip
          :min-width="100"
          label="操作人">
      </el-table-column>

      <el-table-column
          align="center"
          prop="title"
          :min-width="120"
          label="操作">
        <template slot-scope="scope">
          <el-button
              @click="editRow(scope.row)" type="primary"
          >编辑</el-button>
          <el-button
              @click="deleteRow(scope.row)" type="danger"
          >删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="pagination block">
      <pagination
          :current-page.sync="pagination.currentPage"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          @loadData="fetchData"
      />
    </div>
  </div>
  <dialog-edit-speech
    :row-data="rowData"
    :is-super="isSuper"
    :value.sync="showEditDialog"
    :is-add="isAdd"
    @refresh="fetchData"
  />
</div>
</template>

<script>
import { getSpeechList , deleteSpeech } from "@/api/content";
import {statusCode} from "@/util/statusCode";
import Pagination from "@/pages/Layout/Pagination";
import DialogEditSpeech from "@/pages/speech/DialogEditSpeech";
import StatusButton from "@components/common/StatusButton";
import {updateAdmin} from "@/api/control";
import dayjs from 'dayjs'
export default {
  name: "SpeechList",
  components: {
    Pagination,
    DialogEditSpeech,
    StatusButton
  },
  props: {
    isSuper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAdd: false,
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      showEditDialog: false,
      rowData: {}
    }
  },
  computed: {
    apiParams() {
      return {
        type: this.isSuper ? 2 : 1,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getSpeechList(this.apiParams)
        if (data.code === statusCode.success) {
          this.total = data.data.total
          this.pagination.total = data.data.total
          this.tableData = JSON.parse(JSON.stringify(data.data.data)) || []
          // this.tableData = list.reduce((all, item) => {
          //   const time = new Date(item.update_time)
          //   console.log(time, 'time')
          //   all.push({
          //     ...item,
          //     updateTime: dayjs(time).format('YYYY-MM-DD HH:ss:mm')
          //   })
          //   return all
          // }, [])
          this.loading = false
        }
      } catch (e) {
        console.log(e, '出错了')
      }
    },
    editRow(row) {
      this.rowData = row
      this.isAdd = false
      this.showEditDialog = true
    },
    deleteRow(row) {
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let msg = '操作成功',type = 'success'
        // this.$emit('change')
        const {data } = await deleteSpeech(row.id)
        if (data.code === statusCode.success) {
          this.fetchData()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        });
      })
    },
    addContent() {
      this.rowData = {}
      this.isAdd = true
      this.showEditDialog = true
    },
    async changeSwitch(item) {
      let msg = '操作成功',type = 'success'
      let sendJson = {
        id: item.id,
        status: item.status == 1 ? 2 : 1
      }
      let {data} = await updateAdmin(sendJson)
      if (data.code === statusCode.success) {
        this.addDialog = false
        await this.init()
      } else {
        msg = data.msg
        type = 'warning'
      }
      this.$message({
        message: msg,
        type,
        duration: 2000
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .table-box {
    height: calc(100% - 100px);
  }
  .pagination {
    margin-top: 10px;
  }
}

/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  //border: 0;
}

/deep/ .el-table tr {
  cellspacing: 10px;
  border: 1px solid #cccccc;
  padding: 15px 0;
}

/deep/ .el-table__body {
  border-collapse: separate;
  //border-spacing: 0 1px;
  background-color: #f6f6f6;
}
</style>

